import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Buntpapier from 'buntpapier'

import config from 'config'
import { redirectToLoginPage } from 'lib/api'

import 'roboto-fontface'
import 'roboto-fontface/css/roboto-condensed/roboto-condensed-fontface.css'
import 'typeface-roboto-mono'
import '@mdi/font/css/materialdesignicons.css'

Vue.config.productionTip = false
;(async () => {
	const queryParams = new URLSearchParams(window.location.search)
	if (!queryParams.has('code')) {
		if (!('cognito_id_token' in localStorage)) {
			throw 'Token is missing'
		}
		await store.dispatch('authenticated')
		await store.dispatch('init-accounts')
		router.beforeEach((to, from, next) => {
			if (!store.getters.authenticated) {
				redirectToLoginPage()
				return
			}
			next()
		})
		return
	}
	const code = queryParams.get('code')
	const response = await window.fetch(`https://${config.signIn.domain}/oauth2/token`, {
		method: 'POST',
		body: `grant_type=authorization_code&client_id=${config.signIn.clientId}&code=${code}&redirect_uri=${config.signIn.redirectURI}`,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	})
	const content = await response.json()
	localStorage.setItem('cognito_id_token', content.id_token)
	localStorage.setItem('cognito_access_token', content.access_token)
	localStorage.setItem('cognito_refresh_token', content.refresh_token)
	await store.dispatch('authenticated')
	await store.dispatch('init-accounts')
	window.location = '/'
})()
	.then(() => {
		Vue.use(Buntpapier)

		new Vue({
			router,
			store,
			render: (h) => h(App),
		}).$mount('#app')
	})
	.catch(() => {
		redirectToLoginPage()
	})
