<template lang="pug">
nav.primary
	.left
		bunt-tabs(:active-tab="activeTab")
			bunt-tab(header="Home", id="home", @selected="tabSelected")
			bunt-tab(header="Code Pipelines", id="code-pipelines", @selected="tabSelected")
			bunt-tab(header="Code Build Projects", id="codebuild-projects", @selected="tabSelected")
			bunt-tab(header="CloudFormation Stacks", id="cloudformation-stacks", @selected="tabSelected")
	.right
		bunt-button#btn-logout(text="log out", icon="power-standby", @click.native="logout()")
</template>

<script>
import { redirectToLoginPage } from 'lib/api'

import '../styles/style.styl'

export default {
	name: 'AppBar',
	computed: {
		activeTab() {
			return this.$route.name?.split(':', 1)[0]
		},
	},
	methods: {
		tabSelected(id) {
			if (this.$route.name === null || this.$route.name.split(':', 1)[0] === id) {
				return // HACK prevent programatic select changing route, see computed.activeTab
			}
			this.$router.replace({ name: id, params: this.$route.params })
		},
		logout() {
			redirectToLoginPage()
		},
	},
}
</script>

<style lang="stylus">
nav.primary
	z-index: 1000
	flex: 0 0 48px
	card()
	background-color: $clr-white
	border-bottom: 2px solid $ax-primary
	display: flex
	justify-content: space-between
	transition: width 0.3s ease
	border-radius: 0
	position: relative

	.bunt-tabs
		width: auto
		tabs-style(
			background-color: transparent,
			color: $clr-secondary-text-light,
			active-color: $clr-primary-text-light,
			indicator-color: $ax-primary
		)
		margin-bottom: 0

		.bunt-tabs-indicator
			height: 5px

		.bunt-tabs-body
			display: none

	#btn-logout
		button-style(style: 'clear')
		height: 100%
</style>
