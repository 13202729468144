<template lang="pug">
.view-home
	.page-header
		h2 Home
</template>

<script>
export default {
	name: 'Home',
}
</script>
