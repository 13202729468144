import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'home',
		component: Home,
	},
	{
		path: '/code-pipelines',
		name: 'code-pipelines',
		component: () => import(/* webpackChunkName: "pipelines" */ '../views/CodePipelines.vue'),
	},
	{
		path: '/codebuild-projects',
		name: 'codebuild-projects',
		component: () => import(/* webpackChunkName: "codebuild" */ '../views/CodeBuildProjects.vue'),
	},
	{
		path: '/cloudformation-stacks',
		name: 'cloudformation-stacks',
		component: () => import(/* webpackChunkName: "codebuild" */ '../views/CloudFormationStacks.vue'),
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

export default router
