import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'
import { initAccounts, listCodePipelineExecutions, listBuildsForProject } from '../lib/api'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		authenticated: false,
		accountIds: [],
		cloudFormationStacks: [],
		cloudFormationStacksFilter: {
			search: '',
			account: 'all',
			region: 'all',
		},
		codeBuildProjects: [],
		codeBuildProjectsFilter: {
			search: '',
			account: 'all',
			region: 'all',
		},
		codePipelines: [],
		reloadInterval: {},
		codePipelinesFilter: {
			search: '',
			account: 'all',
			region: 'all',
		},
	},
	mutations: {
		AUTHENTICATED(state) {
			state.authenticated = true
		},
		SET_ACCOUNT_IDS(state, accountIds) {
			state.accountIds = accountIds
		},
		SET_RELOAD_INTERVAL(state, { id, seconds }) {
			state.reloadInterval = { id, seconds }
		},
		SET_CLOUDFORMATION_STACKS(state, { stacks }) {
			state.cloudFormationStacks = stacks
		},
		SET_CLOUDFORMATION_STACKS_LOADING(state) {
			state.cloudFormationStacks.map((stack) => {
				stack.statusLoading = true
			})
		},
		SET_CLOUDFORMATION_STACK_FILTER_SEARCH(state, { search }) {
			state.cloudFormationStacksFilter.search = search
		},
		SET_CLOUDFORMATION_STACK_FILTER_ACCOUNT(state, { account }) {
			state.cloudFormationStacksFilter.account = account
		},
		SET_CLOUDFORMATION_STACK_FILTER_REGION(state, { region }) {
			state.cloudFormationStacksFilter.region = region
		},
		SET_CODE_BUILD_PROJECTS(state, projects) {
			state.codeBuildProjects = projects
		},
		SET_CODE_BUILD_PROJECT_LOADING(state, projectKey) {
			const project = state.codeBuildProjects.find((p) => p.key === projectKey)
			if (project === undefined) return
			project.buildsLoading = true
		},
		SET_CODE_BUILD_PROJECT_BUILDS_OUTDATED(state, { projectKey }) {
			const project = state.codeBuildProjects.find((p) => p.key === projectKey)
			if (project === undefined || project.buildsLoading) return
			project.builds = [
				{ buildStatus: 'unknown' },
				{ buildStatus: 'unknown' },
				{ buildStatus: 'unknown' },
				{ buildStatus: 'unknown' },
				{ buildStatus: 'unknown' },
			]
		},
		SET_CODE_BUILD_PROJECT_BUILDS(state, { projectKey, builds }) {
			const project = state.codeBuildProjects.find((p) => p.key === projectKey)
			if (project === undefined) return
			project.builds = builds
			project.hide = builds.every((build) => build.source?.type === 'CODEPIPELINE')
			project.buildsLastReload = moment()
			project.buildsLoading = false
		},
		SET_CODE_BUILD_PROJECTS_FILTER_SEARCH(state, { search }) {
			state.codeBuildProjectsFilter.search = search
		},
		SET_CODE_BUILD_PROJECTS_FILTER_ACCOUNT(state, { account }) {
			state.codeBuildProjectsFilter.account = account
		},
		SET_CODE_BUILD_PROJECTS_FILTER_REGION(state, { region }) {
			state.codeBuildProjectsFilter.region = region
		},
		SET_CODE_PIPELINES(state, pipelines) {
			state.codePipelines = pipelines
		},
		SET_CODE_PIPELINE_LOADING(state, pipelineKey) {
			const pipeline = state.codePipelines.find((p) => p.key === pipelineKey)
			if (pipeline === undefined) return
			pipeline.executionSummariesLoading = true
		},
		SET_CODE_PIPELINE_EXECUTIONS_OUTDATED(state, { pipelineKey }) {
			const pipeline = state.codePipelines.find((p) => p.key === pipelineKey)
			if (pipeline === undefined || pipeline.executionSummariesLoading) return
			pipeline.executionSummaries = [
				{ status: 'unknown' },
				{ status: 'unknown' },
				{ status: 'unknown' },
				{ status: 'unknown' },
				{ status: 'unknown' },
			]
		},
		SET_CODE_PIPELINE_EXECUTIONS(state, { pipelineKey, executions }) {
			const pipeline = state.codePipelines.find((p) => p.key === pipelineKey)
			if (pipeline === undefined) return
			pipeline.executionSummaries = executions
			pipeline.executionSummariesLastReload = moment()
			pipeline.executionSummariesLoading = false
		},
		SET_CODE_PIPELINES_FILTER_SEARCH(state, { search }) {
			state.codePipelinesFilter.search = search
		},
		SET_CODE_PIPELINES_FILTER_ACCOUNT(state, { account }) {
			state.codePipelinesFilter.account = account
		},
		SET_CODE_PIPELINES_FILTER_REGION(state, { region }) {
			state.codePipelinesFilter.region = region
		},
	},
	actions: {
		async authenticated({ commit }) {
			commit('AUTHENTICATED')
		},
		async 'init-accounts'({ commit }) {
			const accountIds = await initAccounts()
			commit('SET_ACCOUNT_IDS', accountIds)
		},
		async 'set-reload-interval'({ commit }, { id, seconds }) {
			commit('SET_RELOAD_INTERVAL', { id, seconds })
		},
		async 'set-cloudformation-stacks'({ commit }, { stacks }) {
			commit('SET_CLOUDFORMATION_STACKS', { stacks })
		},
		async 'set-cloudformation-stacks-loading'({ commit }) {
			commit('SET_CLOUDFORMATION_STACKS_LOADING')
		},
		async 'update-cloudformation-stacks-filter-search'({ commit }, { search }) {
			commit('SET_CLOUDFORMATION_STACK_FILTER_SEARCH', { search })
		},
		async 'update-cloudformation-stacks-filter-account'({ commit }, { account }) {
			commit('SET_CLOUDFORMATION_STACK_FILTER_ACCOUNT', { account })
		},
		async 'update-cloudformation-stacks-filter-region'({ commit }, { region }) {
			commit('SET_CLOUDFORMATION_STACK_FILTER_REGION', { region })
		},
		async 'set-code-build-projects'({ commit }, { projects }) {
			commit('SET_CODE_BUILD_PROJECTS', projects)
		},
		async 'set-code-build-project-builds-outdated'({ commit }, { projectKey }) {
			commit('SET_CODE_BUILD_PROJECT_BUILDS_OUTDATED', { projectKey })
		},
		async 'update-code-build-project-builds'({ commit, state }, { projectKey }) {
			const project = state.codeBuildProjects.find((p) => p.key === projectKey)
			if (project === undefined || project.buildsLoading) {
				return
			}
			commit('SET_CODE_BUILD_PROJECT_LOADING', projectKey)

			const builds = await listBuildsForProject(project.accountId, project.region, project.name)
			while (builds.length < 5) {
				builds.push({ buildStatus: 'unknown' })
			}
			commit('SET_CODE_BUILD_PROJECT_BUILDS', { projectKey, builds })
		},
		async 'update-code-build-projects-filter-search'({ commit }, { search }) {
			commit('SET_CODE_BUILD_PROJECTS_FILTER_SEARCH', { search })
		},
		async 'update-code-build-projects-filter-account'({ commit }, { account }) {
			commit('SET_CODE_BUILD_PROJECTS_FILTER_ACCOUNT', { account })
		},
		async 'update-code-build-projects-filter-region'({ commit }, { region }) {
			commit('SET_CODE_BUILD_PROJECTS_FILTER_REGION', { region })
		},
		async 'set-code-pipelines'({ commit }, { pipelines }) {
			commit('SET_CODE_PIPELINES', pipelines)
		},
		async 'set-code-pipeline-executions-outdated'({ commit }, { pipelineKey }) {
			commit('SET_CODE_PIPELINE_EXECUTIONS_OUTDATED', { pipelineKey })
		},
		async 'update-code-pipeline-executions'({ commit, state }, { pipelineKey }) {
			const pipeline = state.codePipelines.find((p) => p.key === pipelineKey)
			if (pipeline === undefined || pipeline.executionSummariesLoading) {
				return
			}
			commit('SET_CODE_PIPELINE_LOADING', pipelineKey)

			const executions = await listCodePipelineExecutions(pipeline.accountId, pipeline.region, pipeline.name)
			while (executions.length < 5) {
				executions.push({ status: 'unknown' })
			}
			commit('SET_CODE_PIPELINE_EXECUTIONS', { pipelineKey, executions })
		},
		async 'update-code-pipelines-filter-search'({ commit }, { search }) {
			commit('SET_CODE_PIPELINES_FILTER_SEARCH', { search })
		},
		async 'update-code-pipelines-filter-account'({ commit }, { account }) {
			commit('SET_CODE_PIPELINES_FILTER_ACCOUNT', { account })
		},
		async 'update-code-pipelines-filter-region'({ commit }, { region }) {
			commit('SET_CODE_PIPELINES_FILTER_REGION', { region })
		},
	},
	getters: {
		authenticated: (state) => state.authenticated,
		reloadIntervalId: (state) => state.reloadInterval.id,
		reloadIntervalSeconds: (state) => state.reloadInterval.seconds,
	},
})
