<template lang="pug">
#v-app
	app-bar
	router-view
</template>

<script>
import AppBar from 'components/AppBar'

import './styles/style.styl'

export default {
	components: { AppBar },
}
</script>

<style lang="stylus"></style>
