import AWS from 'aws-sdk'
import config from 'config'

window.AWS = AWS
const Logins = {}
Logins[config.userPool] = localStorage.cognito_id_token
const initialCognitoCredentials = new AWS.CognitoIdentityCredentials({
	IdentityPoolId: config.identityPoolId,
	Logins,
})
AWS.config.region = 'eu-west-1'
AWS.config.credentials = initialCognitoCredentials

// TODO: use the store instead?
const credentialsLookup = new Map([['global', initialCognitoCredentials]])
window.credentialsLookup = credentialsLookup

const signinURL = `https://${config.signIn.domain}/login?client_id=${config.signIn.clientId}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${config.signIn.redirectURI}`

const redirectToLoginPage = () => {
	localStorage.removeItem('cognito_id_token')
	localStorage.removeItem('cognito_access_token')
	localStorage.removeItem('cognito_refresh_token')
	window.location.href = signinURL
}

const refreshCognitoCredentials = async function () {
	const refreshParams = {
		ClientId: config.signIn.clientId,
		AuthFlow: 'REFRESH_TOKEN_AUTH',
		AuthParameters: { REFRESH_TOKEN: localStorage.cognito_refresh_token },
	}
	const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider()
	try {
		const response = await cognitoidentityserviceprovider
			.makeUnauthenticatedRequest('initiateAuth', refreshParams)
			.promise()
		localStorage.setItem('cognito_id_token', response.AuthenticationResult.IdToken)
		localStorage.setItem('cognito_access_token', response.AuthenticationResult.AccessToken)
		initialCognitoCredentials.params.Logins[config.userPool] = localStorage.cognito_id_token
		console.log('Updated id token')
	} catch (err) {
		console.log('Token refresh failed')
		console.log(err, err.stack)
		redirectToLoginPage()
		return
	}
	try {
		await initialCognitoCredentials.refreshPromise()
		console.log('Refreshed credentials')
	} catch (err) {
		console.log('Refreshing credentials failed')
		console.log(err, err.stack)
		// FIXME: Remove comment
		// redirectToLoginPage()
	}
}

const initAccount = async function (accountId, accountRole) {
	const sts = new AWS.STS({})

	const assumeRole = async function (accountId, accountRole) {
		try {
			return await sts
				.assumeRole({
					RoleArn: `arn:aws:iam::${accountId}:role/${accountRole}`,
					RoleSessionName: 'mfk-cognito-experiment',
				})
				.promise()
		} catch (err) {
			console.log(err, err.stack)
			console.log('Refresh required? (assume role)')
			return undefined
		}
	}

	const assumed = await assumeRole(accountId, accountRole)
	const accountCredentials = sts.credentialsFrom(assumed)
	credentialsLookup.set(accountId, accountCredentials)
}

const initAccounts = async function () {
	try {
		await AWS.config.credentials.getPromise()
	} catch (err) {
		console.log(err, err.stack)
		console.log('DEBUG credentials need a refresh')
		await refreshCognitoCredentials()
	}
	if (AWS.config.credentials.needsRefresh() === true) {
		console.log('DEBUG credentials need a refresh')
		await refreshCognitoCredentials()
	}
	const promises = []
	for (const account of config.secondaryAccounts) {
		promises.push(initAccount(account.accountId, account.rolePath + account.roleName))
	}
	await Promise.all(promises)
	return Array.from(credentialsLookup.keys())
}

const refreshCredentials = initAccounts

const getAccountIds = async function () {
	return Array.from(credentialsLookup.keys())
}

const createEc2Client = async function (accountId) {
	const credentials = credentialsLookup.get(accountId)
	if (!credentials) {
		throw `Found no credentials for account ${accountId}`
	}
	return new AWS.EC2({ credentials })
}

const getAvailableRegions = async function (accountId) {
	let ec2 = await createEc2Client(accountId)
	let regions = null
	try {
		regions = await ec2.describeRegions({}).promise()
	} catch (err) {
		console.log(err, err.stack)
		console.log('Refresh required? (get available regions)')
		await refreshCredentials()
		let ec2 = await createEc2Client(accountId)
		try {
			regions = await ec2.describeRegions({}).promise()
		} catch (err) {
			console.log(err, err.stack)
			console.log('Refresh failed? (get available regions)')
			redirectToLoginPage()
			return []
		}
	}
	const regionNames = []
	for (const region of regions.Regions) {
		regionNames.push(region.RegionName)
	}
	return regionNames
}

// Code Pipelines

const createCodepipelineClient = async function (accountId, region) {
	const credentials = credentialsLookup.get(accountId)
	if (!credentials) {
		throw `Found no credentials for account ${accountId}`
	}
	return new AWS.CodePipeline({
		credentials,
		region,
	})
}

const listCodePipelines = async function (accountId, region) {
	let codepipelineClient = await createCodepipelineClient(accountId, region)

	const pipelines = []
	const params = {}
	let nextToken = null
	let credentialsRefreshed = false
	do {
		if (nextToken !== null) {
			params.nextToken = nextToken
		}
		try {
			const response = await codepipelineClient.listPipelines(params).promise()
			pipelines.push(...response.pipelines)
			nextToken = response.nextToken || null
		} catch (err) {
			if (credentialsRefreshed) {
				// refresh credentials only once per loop
				console.log(err, err.stack)
				console.log('Refresh failed? (list code pipelines)')
				redirectToLoginPage()
				return []
			}
			await refreshCredentials()
			codepipelineClient = await createCodepipelineClient(accountId, region)
			credentialsRefreshed = true
		}
	} while (nextToken !== null)
	return pipelines
}

const listCodePipelineExecutions = async function (accountId, region, pipelineName) {
	const codepipelineClient = await createCodepipelineClient(accountId, region)
	const params = { pipelineName, maxResults: 5 }
	try {
		const response = await codepipelineClient.listPipelineExecutions(params).promise()
		return response.pipelineExecutionSummaries
	} catch (err) {
		if (typeof err === 'object' && 'code' in err && err.code === 'ThrottlingException') {
			console.log(err.message, '(list code-pipeline executions)')
			return []
		}
		await refreshCredentials()
		const refreshedCodepipelineClient = await createCodepipelineClient(accountId, region)
		try {
			const response = await refreshedCodepipelineClient.listPipelineExecutions(params).promise()
			return response.pipelineExecutionSummaries
		} catch (err) {
			console.log(err, err.stack)
			console.log('Refresh failed? (list code-pipeline executions)')
			redirectToLoginPage()
			return []
		}
	}
}

// CodeBuild Projects

const createCodeBuildClient = async function (accountId, region) {
	const credentials = credentialsLookup.get(accountId)
	if (!credentials) {
		throw `Found no credentials for account ${accountId}`
	}
	return new AWS.CodeBuild({
		credentials,
		region,
	})
}

const listCodeBuildProjects = async function (accountId, region) {
	let codebuildClient = await createCodeBuildClient(accountId, region)

	const projects = []
	const params = {}
	let nextToken = null
	let credentialsRefreshed = false
	do {
		if (nextToken !== null) {
			params.nextToken = nextToken
		}
		try {
			const response = await codebuildClient.listProjects(params).promise()
			projects.push(...response.projects)
			nextToken = response.nextToken || null
		} catch (err) {
			if (credentialsRefreshed) {
				// refresh credentials only once per loop
				console.log(err, err.stack)
				console.log('Refresh failed? (list codebuild projects)')
				redirectToLoginPage()
				return []
			}
			await refreshCredentials()
			codebuildClient = await createCodeBuildClient(accountId, region)
			credentialsRefreshed = true
		}
	} while (nextToken !== null)

	return projects
}

const listBuildsForProject = async function (accountId, region, projectName) {
	let codebuildClient = await createCodeBuildClient(accountId, region)

	const buildIds = []
	const params = { projectName }
	try {
		const response = await codebuildClient.listBuildsForProject(params).promise()
		buildIds.push(...response.ids.slice(0, 5))
	} catch (err) {
		if (typeof err === 'object' && 'code' in err && err.code === 'ThrottlingException') {
			console.log(err.message, '(list builds for project)')
			return []
		}
		await refreshCredentials()
		codebuildClient = await createCodeBuildClient(accountId, region)
		try {
			const response = await codebuildClient.listBuildsForProject(params).promise()
			buildIds.push(...response.ids.slice(0, 5))
		} catch (err) {
			console.log(err, err.stack)
			console.log('Refresh failed? (list builds for project)')
			redirectToLoginPage()
			return []
		}
	}

	try {
		const response = await codebuildClient.batchGetBuilds({ ids: buildIds }).promise()
		return response.builds
	} catch (err) {
		if (typeof err === 'object' && 'code' in err && err.code === 'ThrottlingException') {
			console.log(err.message, '(list builds for project)')
			return []
		}
		console.log(err, err.stack)
		console.log('Failed to list builds for project')
	}
	return []
}

// CloudFormation Stacks

const createCloudformationClient = async function (accountId, region) {
	const credentials = credentialsLookup.get(accountId)
	if (!credentials) {
		throw `Found no credentials for account ${accountId}`
	}
	return new AWS.CloudFormation({
		credentials,
		region,
	})
}

const listCloudFormationStacks = async function (accountId, region) {
	let cloudformationClient = await createCloudformationClient(accountId, region)

	const stacks = []
	const params = {}
	let nextToken = null
	let credentialsRefreshed = false
	do {
		if (nextToken !== null) {
			params.NextToken = nextToken
		}
		try {
			const response = await cloudformationClient.listStacks(params).promise()
			stacks.push(...response.StackSummaries)
			nextToken = response.NextToken || null
		} catch (err) {
			if (credentialsRefreshed) {
				// refresh credentials only once per loop
				console.log(err, err.stack)
				console.log('Refresh failed? (list cloud formation stacks)')
				redirectToLoginPage()
				return []
			}
			await refreshCredentials()
			cloudformationClient = await createCloudformationClient(accountId, region)
			credentialsRefreshed = true
		}
	} while (nextToken !== null)

	return stacks
}

export {
	initAccounts,
	getAccountIds,
	getAvailableRegions,
	listCodePipelines,
	listCodePipelineExecutions,
	listCodeBuildProjects,
	listBuildsForProject,
	listCloudFormationStacks,
	redirectToLoginPage,
}
